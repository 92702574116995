import {getList, getAllClazzListByTeachers} from "@/api/exp/LoginLogApi";
import {CommonModel} from "@/model/CommonModel";

/**
 * 登录日志model
 */
class LoginLogModel {
    // 获取列表
    static async getList(query) {
        let [data] = await getList(query)
        if (data.code === 20000) {
            return data.data;
        } else {
            return [];
        }
    }

    // 获取指定教师下属的所有教学班级列表
    static async getAllClazzListByTeachers(schoolId, teacherList = []) {
        let [data] = await getAllClazzListByTeachers(schoolId, teacherList)
        if (data.code === 20000) {
            return data.data.map(item => ({
                value: item.id,
                label: item.name
            }));
        } else {
            return [];
        }
    }
}

export {LoginLogModel} 
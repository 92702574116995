import {request_async} from "@/utils/requestAsync";
import {API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

// 获取登录日志列表
export async function getList(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/loginLog/list`, "post_json", params);
}

// 获取指定教师下属的所有教学班级列表
export async function getAllClazzListByTeachers(schoolId, teacherList) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/loginLog/allClazzListByTeachers?schoolId=${schoolId}`, "post_json", teacherList);
} 
import {getOneSchoolAllTeacherList} from "@/api/exp/TeacherApi";

/**
 教师model
 */
class TeacherModel {
  // 获取列表-分页
  static async getOneSchoolAllTeacherList(schoolId) {
    let [result] = await getOneSchoolAllTeacherList(schoolId)
    if(result.code==20000){
      return result.data
    }else{
      return []
    }
  }
}

export {TeacherModel}

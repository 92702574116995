<template>
  <div class="page-container">
    <!--筛选-->
    <div class="filter-container clearFix">
      <list-search-filter :search-filter="lists.searchFilter"
                          @clickSearchFilterBtn="query=>ListMethods().clickSearchFilterBtn(query)">
        <!--  操作  -->
        <div slot="right-container">
          <el-button class="el-button" type="primary" size="small"
                     v-if="learnRecordSchoolList.indexOf(userInfo.schoolId)>-1"
                     @click="LearnLogMethods().clickOpenExportLearnLogDialogBtn()">批量导出学习记录
          </el-button>
          <el-button class="el-button" type="success" size="small"
                     @click="ListMethods().clickOpenExportDialogBtn()">批量导出成绩
          </el-button>
        </div>
      </list-search-filter>
    </div>
    <!--列表-->
    <div class="table-container">
      <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="lists.list" v-loading="lists.loading"
                element-loading-text="加载中" fit
                style="width: 100%;" @sort-change="sort=>ListMethods().sortChange(sort)">
        <el-table-column align="center" label="操作" width="100"
                         class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="text" size="mini" round
                       @click="ListMethods().clickViewBtn(scope.row,scope.$index)">成绩详情
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="学期">
          <template slot-scope="scope">
            <span>{{ scope.row.semestername }}</span>
          </template>
        </el-table-column>
        <el-table-column label="班级" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.clazzname }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实验课程" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.experimentname }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实验开放时间" align="center" width="280" prop="openstarttime" :sortable="'custom'">
          <template slot-scope="scope">
            <span>{{ scope.row.openstarttime | dateFormat }}-{{ scope.row.openendtime | dateFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实验状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.experimentstatus===0" class="status0"><i class="statusDot"></i>未开始</span>
            <span v-if="scope.row.experimentstatus===1" class="status1"><i class="statusDot"></i>进行中</span>
            <span v-if="scope.row.experimentstatus===2" class="status2"><i class="statusDot"></i>已结束</span>
          </template>
        </el-table-column>
        <el-table-column label="班级最高分" width="120" align="center" prop="max" :sortable="true">
          <template slot-scope="scope">
            <span v-if="scope.row.experimentstatus===2">
              <!--如果实验已完成，且没有任何人完成实验，分数显示0分-->
              <span v-if="scope.row.max!==null">{{ scope.row.max | numberFormat }}</span>
              <span v-else>0</span>
            </span>
            <span v-else>
              <span v-if="scope.row.max!==null">{{ scope.row.max | numberFormat }}</span>
              <span v-else>--</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="班级最低分" width="120" align="center" prop="min" :sortable="true">
          <template slot-scope="scope">
            <span v-if="scope.row.experimentstatus===2">
              <span v-if="scope.row.min!==null">{{ scope.row.min | numberFormat }}</span>
              <span v-else>0</span>
            </span>
            <span v-else>
              <span v-if="scope.row.min!==null">{{ scope.row.min | numberFormat }}</span>
              <span v-else>--</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="班级平均分" width="120" align="center" prop="average" :sortable="true">
          <template slot-scope="scope">
            <span v-if="scope.row.experimentstatus===2">
              <span v-if="scope.row.average!==null">{{ scope.row.average | numberFormat }}</span>
              <span v-else>0</span>
            </span>
            <span v-else>
              <span v-if="scope.row.average!==null">{{ scope.row.average | numberFormat }}</span>
              <span v-else>--</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="完成实验情况" align="center">
          <template slot-scope="scope">
            <span>{{
                scope.row.finishedUserNumber
              }}/{{
                scope.row.totalUserNumber
              }} ({{ (scope.row.finishedUserNumber / scope.row.totalUserNumber * 100).toFixed(0) }}%)</span>
          </template>
        </el-table-column>
        <el-table-column label="填写报告情况" align="center">
          <template slot-scope="scope">
            <span>{{
                scope.row.reportedUserNumber
              }}/{{
                scope.row.totalUserNumber
              }} ({{ (scope.row.reportedUserNumber / scope.row.totalUserNumber * 100).toFixed(0) }}%)</span>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <!--列表分页-->
    <div class="pagination-container">
      <el-pagination background @current-change="(number)=>ListMethods().pageChange(number)"
                     :current-page.sync="lists.pages.number" :page-size.sync="lists.pages.size"
                     layout="total,prev, pager, next,sizes,jumper" :total="lists.pages.totalElements"
                     @size-change="(size)=>ListMethods().pageLimitChange(size)"
                     :page-count="lists.pages.totalPages">
      </el-pagination>
    </div>
    <!--弹窗-批量成绩导出筛选-->
    <el-dialog
        :close-on-click-modal="false"
        title="批量导出实验成绩"
        :visible.sync="exportDialog.dialog"
        width="660px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <div class="flex flex-around flex-wrap dialog-filter">
          <div class="li">
            <span>学期：</span>
            <el-select v-model="exportDialog.semesterId" class="select" size="small">
              <el-option v-for="(item1,index1) in lists.searchFilter.filter[0].data" :key="index1" :value="item1.value"
                         :label="item1.label"></el-option>
            </el-select>
          </div>
          <div class="li">
            <span>教学班：</span>
            <el-select v-model="exportDialog.clazzId" class="select" size="small">
              <el-option v-for="(item1,index1) in lists.searchFilter.filter[2].data" :key="index1" :value="item1.value"
                         :label="item1.label"></el-option>
            </el-select>
          </div>
          <div class="li">
            <span>实验课程：</span>
            <el-select v-model="exportDialog.experimentId" class="select" size="small">
              <el-option v-for="(item1,index1) in lists.searchFilter.filter[3].data" :key="index1" :value="item1.value"
                         :label="item1.label"></el-option>
            </el-select>
          </div>
          <!--          <div class="li">-->
          <!--            <span>实验状态：</span>-->
          <!--            <el-select v-model="exportDialog.experimentStatus" class="select" size="small">-->
          <!--              <el-option v-for="(item1,index1) in lists.searchFilter.filter[4].data" :key="index1" :value="item1.value"-->
          <!--                         :label="item1.label"></el-option>-->
          <!--            </el-select>-->
          <!--          </div>-->

        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button type="default" size="small" @click="exportDialog.dialog=false">取消</el-button>
          <el-button type="primary" size="small" @click="ListMethods().clickExportBtn()">导出该老师成绩</el-button>
          <el-button type="default" size="small" @click="ListMethods().clickExportAllBtn()">导出所有老师成绩</el-button>
        </div>
      </div>
    </el-dialog>
    <!--弹窗-批量学习记录导出筛选-->
    <el-dialog
        :close-on-click-modal="false"
        title="批量导出学习记录"
        :visible.sync="exportLearnLogDialog.dialog"
        width="1000px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <!-- 筛选区域 -->
        <div class="dialog-filter">
          <!-- 时间范围选择 -->
          <div class="filter-item">
            <span class="label">时间范围：</span>
            <el-date-picker
                v-model="exportLearnLogDialog.query.startTime"
                type="datetime"
                placeholder="开始时间"
                size="small"
                style="width: 180px"
                :picker-options="exportLearnLogDialog.startTimeOptions">
            </el-date-picker>
            <span class="separator">至</span>
            <el-date-picker
                :disabled="exportLearnLogDialog.query.startTime === ''"
                v-model="exportLearnLogDialog.query.endTime"
                type="datetime"
                placeholder="结束时间"
                size="small"
                style="width: 180px"
                :picker-options="exportLearnLogDialog.endTimeOptions"
                default-time="23:59:59">
            </el-date-picker>
          </div>
          <!-- 实验选择 -->
          <div class="filter-item">
            <span class="label">实验：</span>
            <el-radio-group v-model="exportLearnLogDialog.selectMode.experiment" size="small">
              <el-radio :label="'all'">全部</el-radio>
              <el-radio :label="'custom'">选择部分</el-radio>
            </el-radio-group>
            <el-select
                v-show="exportLearnLogDialog.selectMode.experiment === 'custom'"
                v-model="exportLearnLogDialog.query.experimentId"
                multiple
                placeholder="请选择实验"
                size="small"
                style="width: 100%; margin-left: 10px">
              <el-option
                  v-for="item in filteredExperimentOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- 教师选择 -->
          <div class="filter-item" v-if="userInfo.asSecretary">
            <span class="label">教师：</span>
            <el-radio-group v-model="exportLearnLogDialog.selectMode.teacher" size="small">
              <el-radio :label="'all'">全部</el-radio>
              <el-radio :label="'custom'">选择部分</el-radio>
            </el-radio-group>
            <el-select
                v-show="exportLearnLogDialog.selectMode.teacher === 'custom'"
                v-model="exportLearnLogDialog.query.teacherId"
                multiple
                placeholder="请选择教师"
                size="small"
                style="width: 100%; margin-left: 10px">
              <el-option
                  v-for="item in exportLearnLogDialog.teacherOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- 教学班选择 -->
          <div class="filter-item">
            <span class="label">教学班：</span>
            <el-radio-group v-model="exportLearnLogDialog.selectMode.clazz" size="small">
              <el-radio :label="'all'">全部</el-radio>
              <el-radio :label="'custom'">选择部分</el-radio>
            </el-radio-group>
            <el-select
                v-show="exportLearnLogDialog.selectMode.clazz === 'custom'"
                v-model="exportLearnLogDialog.query.clazzId"
                multiple
                placeholder="请选择教学班"
                size="small"
                style="width: 100%; margin-left: 10px">
              <el-option
                  v-for="item in exportLearnLogDialog.clazzOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- 行政班选择 -->
          <div class="filter-item">
            <span class="label">行政班：</span>
            <el-radio-group v-model="exportLearnLogDialog.selectMode.adminClazz" size="small">
              <el-radio :label="'all'">全部</el-radio>
              <el-radio :label="'custom'">选择部分</el-radio>
            </el-radio-group>
            <el-select
                v-show="exportLearnLogDialog.selectMode.adminClazz === 'custom'"
                v-model="exportLearnLogDialog.query.administrationClazzId"
                multiple
                filterable
                remote
                reserve-keyword
                placeholder="请输入行政班名称进行搜索"
                :remote-method="v=>LearnLogMethods().getAdministrationClazzList(v)"
                @focus="LearnLogMethods().getAdministrationClazzList('')"
                :loading="exportLearnLogDialog.adminClazzLoading"
                size="small"
                style="width: 100%; margin-left: 10px">
              <el-option
                  v-for="item in exportLearnLogDialog.adminClazzOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <!-- 查询按钮 -->
        <div class="search-btn-container">
          <el-button type="primary"
                     size="small"
                     :loading="exportLearnLogDialog.loading"
                     @click="LearnLogMethods().searchLearnLog()">
            {{ exportLearnLogDialog.loading ? '查询中...' : '查询学习记录' }}
          </el-button>
        </div>

        <!-- 统计信息 -->
        <div class="statistics-container" v-if="exportLearnLogDialog.total > -1">
          <div class="statistics-info">
            共查询到 <span class="highlight">{{ exportLearnLogDialog.total }}</span> 条学习记录
          </div>
        </div>

        <!-- 底部按钮 -->
        <div class="dialog-footer" v-if="exportLearnLogDialog.total > 0">
          <el-button
              type="primary"
              size="small"
              :loading="exportLearnLogDialog.exportLoading"
              :disabled="!allListsLoaded"
              @click="LearnLogMethods().exportExcel()">
            {{
              exportLearnLogDialog.exportLoading ? '导出中...' :
                  !allListsLoaded ? '正在加载必要数据...' : '导出表格'
            }}
          </el-button>
          <el-button type="success" size="small" @click="LearnLogMethods().analyzeData()">数据分析</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 在 template 中添加数据分析弹窗 -->
    <el-dialog
        :close-on-click-modal="false"
        title="学习记录数据分析"
        :visible.sync="analysisDialog.visible"
        width="1200px"
        center
        v-el-drag-dialog>
      <div class="analysis-container">
        <!-- 在统计卡片区域前添加筛选条件展示 -->
        <div class="filter-summary">
          <div class="summary-title">筛选条件</div>
          <div class="summary-content">
            <!-- 时间范围 -->
            <div class="summary-item">
              <span class="label">时间范围：</span>
              <span class="value">
                {{
                  exportLearnLogDialog.query.startTime ? date_format(exportLearnLogDialog.query.startTime, "yyyy-MM-dd HH:mm:ss") : "不限"
                }}
                至
                {{
                  exportLearnLogDialog.query.endTime ? date_format(exportLearnLogDialog.query.endTime, "yyyy-MM-dd HH:mm:ss") : "不限"
                }}
              </span>
            </div>

            <!-- 实验 -->
            <div class="summary-item">
              <span class="label">实验：</span>
              <span class="value">{{ filterSummary.experimentText }}</span>
            </div>

            <!-- 教师 -->
            <div class="summary-item" v-if="userInfo.asSecretary">
              <span class="label">教师：</span>
              <span class="value">{{ filterSummary.teacherText }}</span>
            </div>

            <!-- 教学班 -->
            <div class="summary-item">
              <span class="label">教学班：</span>
              <span class="value">{{ filterSummary.clazzText }}</span>
            </div>

            <!-- 行政班 -->
            <div class="summary-item">
              <span class="label">行政班：</span>
              <span class="value">{{ filterSummary.adminClazzText }}</span>
            </div>
          </div>
        </div>

        <!-- 统计卡片区域 -->
        <div class="statistics-cards">
          <el-row :gutter="20">
            <!-- 第一行 -->
            <el-col :span="userInfo.asSecretary ? 6 : 8">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">学生数量</div>
                  <div class="stat-value">{{ analysisDialog.stats.studentCount }}</div>
                </div>
              </el-card>
            </el-col>

            <!-- 只有教秘才显示教师数量 -->
            <el-col :span="6" v-if="userInfo.asSecretary">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">教师数量</div>
                  <div class="stat-value">{{ analysisDialog.stats.teacherCount }}</div>
                </div>
              </el-card>
            </el-col>

            <el-col :span="userInfo.asSecretary ? 6 : 8">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">教学班数量</div>
                  <div class="stat-value">{{ analysisDialog.stats.clazzCount }}</div>
                </div>
              </el-card>
            </el-col>

            <el-col :span="userInfo.asSecretary ? 6 : 8">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">行政班数量</div>
                  <div class="stat-value">{{ analysisDialog.stats.adminClazzCount }}</div>
                </div>
              </el-card>
            </el-col>
          </el-row>

          <!-- 第二行 -->
          <el-row :gutter="20" style="margin-top: 20px">
            <el-col :span="8">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">实验数量</div>
                  <div class="stat-value">{{ analysisDialog.stats.experimentCount }}</div>
                </div>
              </el-card>
            </el-col>

            <el-col :span="8">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">完成学习数 / 总记录数</div>
                  <div class="stat-value">{{ analysisDialog.stats.finishedCount }} / {{
                      analysisDialog.stats.totalCount
                    }}
                  </div>
                  <div class="stat-sub">完成率: {{ analysisDialog.stats.finishRate }}%</div>
                </div>
              </el-card>
            </el-col>
          </el-row>

          <!-- 第三行 -->
          <el-row :gutter="20" style="margin-top: 20px">
            <el-col :span="12">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">得分统计</div>
                  <div class="stat-value">平均 {{ analysisDialog.stats.avgScore }} 分</div>
                  <div class="stat-sub">最高: {{ analysisDialog.stats.maxScore }}分 / 最低:
                    {{ analysisDialog.stats.minScore }}分
                  </div>
                </div>
              </el-card>
            </el-col>

            <el-col :span="12">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">用时统计</div>
                  <div class="stat-value">平均: {{ analysisDialog.stats.avgTime }}</div>
                  <div class="stat-sub">最长: {{ analysisDialog.stats.maxTime }}</div>
                  <div class="stat-sub">最短: {{ analysisDialog.stats.minTime }}</div>
                  <div class="stat-sub">总计: {{ analysisDialog.stats.totalTime }}</div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <!-- 图表区域 -->
        <div class="chart-container" style="margin-top: 30px">
          <div ref="learningChart" style="width: 100%; height: 400px"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * todo 分数排序现在做的端排 等待后端完成排序
 */
import ListSearchFilter from '@/components/list/listSearchFilter'
import {EnumsModel} from '@/model/EnumsModel'
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_confirm_simple, msg_err, msg_success} from '@/utils/ele_component'
import {date_format, find_obj_from_arr_by_id, objectToLVArr} from '@/utils/common'
import {mapState} from 'vuex'
import {ClazzModel} from "@/model/exp/ClazzModel";
import {CommonModel} from "@/model/CommonModel";
import {ExperimentModel} from "@/model/exp/ExperimentModel";
import {SemesterModel} from "@/model/exp/SemesterModel";
import {StudentModel} from "@/model/exp/StudentModel";
import {ScoreModel} from "@/model/exp/ScoreModel";
import {dateFormat, numberFormat, minuteSecondFilter} from "@/filter/filter";
import {RouterModel} from "@/model/RouterModel";
import {TeacherModel} from "@/model/exp/TeacherModel";
import {LoginLogModel} from "@/model/exp/LoginLogModel";
import {AdministrationClazzModel} from "@/model/exp/AdministrationClazzModel";
import {excel_export_from_json} from "@/utils/excel";
import * as echarts from 'echarts'
import {learnRecordSchoolList} from "@/config/ExpAuthSchoolConfig";

export default {
  name: 'teacherScore',
  // 组件
  components: {ListSearchFilter},
  // 指令
  directives: {
    elDragDialog
  },
  // 过滤器
  filters: {
    dateFormat: dateFormat,
    numberFormat: numberFormat,
    minuteSecondFilter: minuteSecondFilter,
  },
  // 计算属性
  computed: {
    ...mapState({
      userInfo: state => state.user
    }),
    // 学习记录用-过掉全部选项的实验列表
    filteredExperimentOptions() {
      return this.lists.searchFilter.filter[3].data.filter(item => item.value !== '')
    },
    // 筛选条件展示用的计算属性
    filterSummary() {
      return {
        // 实验
        experimentText: this.exportLearnLogDialog.selectMode.experiment === 'all'
            ? '全部'
            : (this.exportLearnLogDialog.query.experimentId.length
                ? this.exportLearnLogDialog.query.experimentId
                    .map(id => this.filteredExperimentOptions.find(opt => opt.value === id)?.label || '-')
                    .join('、')
                : '未选择'),

        // 教师
        teacherText: this.userInfo.asSecretary
            ? (this.exportLearnLogDialog.selectMode.teacher === 'all'
                ? '全部'
                : (this.exportLearnLogDialog.query.teacherId.length
                    ? this.exportLearnLogDialog.query.teacherId
                        .map(id => this.exportLearnLogDialog.teacherOptions.find(opt => opt.value === id)?.label || '-')
                        .join('、')
                    : '未选择'))
            : this.userInfo.name,

        // 教学班
        clazzText: this.exportLearnLogDialog.selectMode.clazz === 'all'
            ? '全部'
            : (this.exportLearnLogDialog.query.clazzId.length
                ? this.exportLearnLogDialog.query.clazzId
                    .map(id => this.exportLearnLogDialog.clazzOptions.find(opt => opt.value === id)?.label || '-')
                    .join('、')
                : '未选择'),

        // 行政班
        adminClazzText: this.exportLearnLogDialog.selectMode.adminClazz === 'all'
            ? '全部'
            : (this.exportLearnLogDialog.query.administrationClazzId.length
                ? this.exportLearnLogDialog.query.administrationClazzId
                    .map(id => this.exportLearnLogDialog.adminClazzOptions.find(opt => opt.value === id)?.label || '-')
                    .join('、')
                : '未选择')
      }
    },
    allListsLoaded() {
      const status = this.exportLearnLogDialog.listLoadingStatus
      return status.teacher && status.clazz && status.adminClazz
    }
  },
  data() {
    return {
      // 权限-可以使用学习记录的学校
      learnRecordSchoolList,
      // 外部方法
      date_format: date_format,
      // 枚举
      enums: EnumsModel,
      // 列表
      lists: {
        list: [],
        loading: false,
        query: {},
        queryBase: {},
        pages: {
          size: 10
        },
        searchFilter: {
          search: [],
          filter: [
            {
              type: 'select',
              label: '学期',
              key: 'semesterid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '年级',
              key: 'gradeid',
              hidden: true,
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '教学班',
              key: 'clazzid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '实验课程',
              key: 'experimentid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '实验状态',
              key: 'experimentalstatus',
              value: '',
              data: objectToLVArr(EnumsModel.experimentStatus, true),
              dataObject: {},
              hidden: true,
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
          ]
        }
      },
      // 实体详情
      entityInfo: {
        title: "新班级",
        type: "add",
        dialog: false,
        filter: [
          // 学
          {
            data: [],
            dataObject: {},
            dataOrigin: [],
          },
          // 业
          {
            data: [],
            dataObject: {},
            dataOrigin: [],
          },
          // 年级
          {
            data: [],
            dataObject: {},
            dataOrigin: [],
          },
        ],
        edit: {},
        // 输入检测
        formRules: {
          'departmentid': {required: true, message: '请选择院', trigger: 'blur'},
          'majorid': {required: true, message: '请输入专业', trigger: 'blur'},
          'gradeid': {required: true, message: '请选择年级', trigger: 'blur'},
          'name': {required: true, message: '请输入班级名称', trigger: 'blur'},
        },
      },
      // 批量导出成绩的弹窗
      exportDialog: {
        dialog: false,
        semesterId: "",
        clazzId: "",
        experimentId: "",
        experimentStatus: ""
      },
      // 量导出学习记录的弹窗
      exportLearnLogDialog: {
        dialog: false,
        total: -1,
        query: {
          type: 2,
          status: 0,
          startTime: "",
          endTime: "",
          schoolId: "",
          experimentId: [],
          teacherId: [],
          clazzId: [],
          administrationClazzId: [],
        },
        // 开时间选择器配置
        startTimeOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        // 结束时间选择器配置
        endTimeOptions: {
          disabledDate(time) {
            // 如果没有选择开始时间，禁用结束时间选择器
            if (!this.exportLearnLogDialog?.query?.startTime) {
              return true;
            }
            // 结束时间不早于开始时间，且不能晚于当前时间
            return time.getTime() < this.exportLearnLogDialog.query.startTime.getTime() ||
                time.getTime() > Date.now();
          }
        },
        // 教师选项
        teacherOptions: [],
        // 行政班选项
        adminClazzOptions: [],
        adminClazzAllOptions: [], // 所有行政班选项缓存
        adminClazzLoading: false, // 行政班加载状态
        adminClazzOptionsCache: {}, // 行政班搜索结果缓存 {searchKey: options}
        isFirstFocus: true, // 是否是首次获取焦点
        selectMode: {
          experiment: 'all',
          teacher: 'all',
          clazz: 'all',
          adminClazz: 'all'
        },
        clazzOptions: [], // 添加教学班选项缓存
        loading: false, // 查询加载状态
        result: [], // 查询结果数据
        exportLoading: false, // 导出加载状态
        listLoadingStatus: {
          teacher: false,
          clazz: false,
          adminClazz: false
        },
      },
      analysisDialog: {
        visible: false,
        stats: {
          studentCount: 0,
          teacherCount: 0,
          clazzCount: 0,
          adminClazzCount: 0,
          experimentCount: 0,
          totalCount: 0,
          finishedCount: 0,
          finishRate: '0.0',
          maxScore: 0,
          minScore: 0,
          avgScore: 0,
          maxTime: '-',
          minTime: '-',
          avgTime: '-',
          totalTime: '-'
        },
        chart: null
      }
    }
  },
  async mounted() {
    // 初始化筛选
    await this.ListMethods().initFilter()
    // 获取列表
    this.ListMethods().getList(1, this.lists.pages.size, this.lists.query)
    window.addEventListener('resize', this.resizeChart)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeChart)
  },
  created() {
    // 将 endTimeOptions 的 this 绑定到 Vue 实例
    this.exportLearnLogDialog.endTimeOptions.disabledDate =
        this.exportLearnLogDialog.endTimeOptions.disabledDate.bind(this);
    console.log(this.learnRecordSchoolList)
  },
  methods: {
    resizeChart() {
      if (this.analysisDialog.chart) {
        this.analysisDialog.chart.resize()
      }
    },
    // 列表Methods
    ListMethods() {
      let $this = this
      return {
        // 点击批量导出成绩按钮
        clickOpenExportDialogBtn() {
          $this.exportDialog.semesterId = $this.lists.searchFilter.filter[0].value
          $this.exportDialog.clazzId = $this.lists.searchFilter.filter[2].value
          $this.exportDialog.experimentId = $this.lists.searchFilter.filter[3].value
          $this.exportDialog.experimentStatus = $this.lists.searchFilter.filter[4].value
          $this.exportDialog.dialog = true
        },
        // 点击导出成绩按钮
        clickExportBtn() {
          alert("导出时间较长，请耐心等待，要关闭窗口！")
          ScoreModel.exportMultiple($this.userInfo.userid, $this.exportDialog.semesterId, $this.exportDialog.clazzId, $this.exportDialog.experimentId, $this.exportDialog.experimentStatus)
        },
        // 获取列表
        async getList(page, size, query) {
          query = Object.assign(query, $this.lists.queryBase)
          $this.lists.loading = true;
          [$this.lists.list, $this.lists.pages] = await ScoreModel.getList(page, size, query)
          $this.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.lists.pages.size, $this.lists.query)
        },
        // 分页-改变每页显示
        async pageLimitChange(size) {
          this.getList($this.lists.pages.number, size, $this.lists.query)
        },
        // 排序被改变
        async sortChange(sort) {
          if (sort.column.sortable === 'custom') {
            let querySort = $this.lists.query.sort
            querySort = CommonModel.elementTableSort(sort)
            // 时间按开始和结束排序
            querySort = querySort.replace("opentime,desc", "openendtime,desc")
            querySort = querySort.replace("opentime,asc", "openstarttime,asc")
            $this.$set($this.lists.query, "sort", querySort)
            this.getList(1, $this.lists.pages.size, $this.lists.query)
          }
        },
        // 初始化筛选列表
        async initFilter(type) {
          // 获取学期列表
          if (!type || type === 0) {
            let semesterList = await SemesterModel.getList(0, 0, {
              teacherid: $this.userInfo.userid
            })
            let generateListFilter0 = CommonModel.generateListFilterOptions('name', 'id', semesterList, true)
            $this.$set($this.lists.searchFilter.filter[0], "data", generateListFilter0[0])
            $this.$set($this.lists.searchFilter.filter[0], "dataObject", generateListFilter0[1])
            $this.$set($this.lists.searchFilter.filter[0], "dataOrigin", semesterList)
            // 默认选中当前学期能，如果没有就选中最近学期 22-04-15 默认显当前学期实验，提高载入速度 edgar todo 230101暂时关闭
            // let defaultSemesterId = ""
            // for (let i = 0; i < semesterList.length; i++) {
            //   let li = semesterList[i]
            //   if (li.iscurrentsemester) {// 如果有当前时间的学期
            //     defaultSemesterId = li.id
            //   }
            //   if (li.isdefaultselected && !defaultSemesterId) {// 如果有最近的学期
            //     defaultSemesterId = li.id
            //   }
            //   if (!li.isdefaultselected && !defaultSemesterId) {// 如果没最近的学期
            //     defaultSemesterId = li.id
            //   }
            // }
            // $this.$set($this.lists.searchFilter.filter[0], "value", defaultSemesterId)
            // $this.$set($this.lists, "query", {
            //   semesterid: defaultSemesterId
            // })
          }
          // 获取班级列表
          if (!type || type === 1) {
            let clazzList = (await ClazzModel.getList(1, -1, {}))[0]
            let generateListFilter1 = CommonModel.generateListFilterOptions('clazzName', 'clazzid', clazzList, true)
            $this.$set($this.lists.searchFilter.filter[2], "data", generateListFilter1[0])
            $this.$set($this.lists.searchFilter.filter[2], "dataObject", generateListFilter1[1])
            $this.$set($this.lists.searchFilter.filter[2], "dataOrigin", clazzList)
          }
          // 获取实验列表
          if (!type || type === 2) {
            let experimentList = (await ExperimentModel.getTeacherExperimentList($this.userInfo))
            let generateListFilter1 = CommonModel.generateListFilterOptions('name', 'id', experimentList, true)
            $this.$set($this.lists.searchFilter.filter[3], "data", generateListFilter1[0])
            $this.$set($this.lists.searchFilter.filter[3], "dataObject", generateListFilter1[1])
            $this.$set($this.lists.searchFilter.filter[3], "dataOrigin", experimentList)
          }
          $this.$forceUpdate()
        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          this.getList(1, $this.lists.pages.size, query)
          $this.lists.query = query
        },
        // 点击详情按钮
        clickViewBtn(entity) {
          let scoreInfoTitle = entity.semestername + " " + entity.clazzname + " " + entity.experimentname + " 实验成绩详情"
          RouterModel.routerPush($this, `/teacher/scoreInfo?title=${scoreInfoTitle}&scheduleId=${entity.id}&clazzid=${entity.clazzid}&experimentid=${entity.experimentid}&semesterid=${entity.semesterid}&experimentname=${encodeURI(entity.experimentname)}&clazzname=${encodeURI(entity.clazzname)}`, "成绩管理 / 成绩详情", {}, '4', [
            {name: "成绩管理", path: "/teacher/score", index: '4'},
            {name: "成绩详情"}
          ])
        },
        clickExportAllBtn() {
          let experimentId = $this.exportDialog.experimentId
          let semesterId = $this.exportDialog.semesterId
          if (experimentId && semesterId) {
            alert("导出时间较长，请耐心等待，不要关闭窗口！")
            ScoreModel.exportOneSchoolAllStudentScore($this.userInfo.schoolId, experimentId, semesterId)
          } else {
            msg_err("请先在上方筛选栏中选择要导出的实验课程和学期!")
          }
        }
      }
    },
    // 学习记录Methods
    LearnLogMethods() {
      let $this = this
      return {
        clickOpenExportLearnLogDialogBtn() {
          $this.exportLearnLogDialog.dialog = true
        },
        // 查询学习记录
        async searchLearnLog() {
          // 构造查询参数
          const query = {
            // 固定参数
            type: 2,
            status: 0,
            schoolId: $this.userInfo.schoolId,

            // 时间范围
            startTime: $this.exportLearnLogDialog.query.startTime,
            endTime: $this.exportLearnLogDialog.query.endTime,

            // 实验ID
            experimentId: $this.exportLearnLogDialog.selectMode.experiment === 'all'
                ? []
                : $this.exportLearnLogDialog.query.experimentId,

            // 教师ID
            teacherId: $this.userInfo.asSecretary
                ? ($this.exportLearnLogDialog.selectMode.teacher === 'all'
                    ? []
                    : $this.exportLearnLogDialog.query.teacherId)
                : [$this.userInfo.userid],

            // 教学班ID
            clazzId: $this.exportLearnLogDialog.selectMode.clazz === 'all'
                ? []
                : $this.exportLearnLogDialog.query.clazzId,

            // 行政班ID
            administrationClazzId: $this.exportLearnLogDialog.selectMode.adminClazz === 'all'
                ? []
                : $this.exportLearnLogDialog.query.administrationClazzId
          }

          // 开始查询，示加载状态
          $this.exportLearnLogDialog.loading = true
          try {
            const result = await LoginLogModel.getList(query)
            // 保存查询结果
            $this.exportLearnLogDialog.result = result
            $this.exportLearnLogDialog.total = result.length
          } catch (error) {
            console.error('查询学习记录失败:', error)
            msg_err(error.message || '查询学习录失败')
            // 清空结果
            $this.exportLearnLogDialog.result = []
            $this.exportLearnLogDialog.total = 0
          } finally {
            $this.exportLearnLogDialog.loading = false
          }
        },
        // 导出表格
        async exportExcel() {
          if (!$this.exportLearnLogDialog.result.length) {
            msg_err('没有可导出的数据')
            return
          }

          $this.exportLearnLogDialog.exportLoading = true
          msg_confirm_simple("正在导出学习记录，请稍等...")

          try {
            // 准备导出数据
            let loginLogs = $this.exportLearnLogDialog.result

            // 构建实验、教师、教学班、行政班的映射关系
            const experimentMap = {}
            $this.filteredExperimentOptions.forEach(item => {
              experimentMap[item.value] = item.label
            })

            const teacherMap = {}
            $this.exportLearnLogDialog.teacherOptions.forEach(item => {
              teacherMap[item.value] = item.label
            })

            const clazzMap = {}
            $this.exportLearnLogDialog.clazzOptions.forEach(item => {
              clazzMap[item.value] = item.label
            })

            const adminClazzMap = {}
            $this.exportLearnLogDialog.adminClazzOptions.forEach(item => {
              adminClazzMap[item.value] = item.label
            })

            // 添加名称信息
            loginLogs = loginLogs.map(log => ({
              ...log,
              experimentName: experimentMap[log.experimentId] || '-',
              teacherName: teacherMap[log.teacherId] || '-',
              clazzName: clazzMap[log.clazzId] || '-',
              administrationClazzName: adminClazzMap[log.administrationClazzId] || '-'
            }))

            // 修改导出表头和字段
            const header = ['学生账号', '学生姓名', '所属教师', '教学班级', '行政班级', '实验项目', '登录时间', '是否完成学习', '传分时间', '本次得分', '学习用时']
            const filter = ['account', 'name', 'teacherName', 'clazzName', 'administrationClazzName', 'experimentName', 'createTime', 'hasScore', 'scoreTime', 'score', 'usedTime']

            function formatJson(filterVal, jsonData) {
              return jsonData.map(v => filterVal.map(j => {
                let value = ''
                switch (j) {
                  case 'createTime':
                  case 'scoreTime':
                    value = v[j] ? date_format(v[j], "yyyy-MM-dd HH:mm:ss") : '-'
                    break
                  case 'hasScore':
                    value = v.scoreTime ? '是' : '否'
                    break
                  case 'score':
                    value = v.scoreTime ? v[j] : '-'
                    break
                  case 'usedTime':
                    value = v.scoreTime ? minuteSecondFilter(v[j] * 1000) : '-'
                    break
                  default:
                    value = v[j] || '-'
                }
                return value
              }))
            }

            excel_export_from_json(
                loginLogs,
                header,
                filter,
                formatJson,
                "学习记录-" + date_format(new Date(), "yyyy-MM-dd HH:mm:ss")
            )
          } catch (error) {
            console.error('导出失败:', error)
            msg_err(error.message || '导出失败')
          } finally {
            $this.exportLearnLogDialog.exportLoading = false
          }
        },
        // 数据分析
        analyzeData() {
          const data = $this.exportLearnLogDialog.result
          if (!data || data.length === 0) {
            msg_err('没有可分析的数据')
            return
          }

          // 1. 基础统计
          const stats = {
            studentCount: new Set(data.map(item => item.account)).size,
            teacherCount: new Set(data.map(item => item.teacherId)).size,
            clazzCount: new Set(data.map(item => item.clazzId)).size,
            adminClazzCount: new Set(data.map(item => item.administrationClazzId)).size,
            experimentCount: new Set(data.map(item => item.experimentId)).size,
            totalCount: data.length,
            finishedCount: data.filter(item => item.scoreTime).length
          }
          stats.finishRate = ((stats.finishedCount / stats.totalCount) * 100).toFixed(1)

          // 2. 完成学习的数据分析
          const finishedData = data.filter(item => item.scoreTime)
          if (finishedData.length > 0) {
            // 分数统计
            stats.maxScore = Math.max(...finishedData.map(item => item.score))
            stats.minScore = Math.min(...finishedData.map(item => item.score))
            stats.avgScore = (finishedData.reduce((sum, item) => sum + item.score, 0) / finishedData.length).toFixed(1)

            // 用时统计
            const formatTime = (seconds) => {
              const hours = Math.floor(seconds / 3600)
              const minutes = Math.floor((seconds % 3600) / 60)
              const secs = seconds % 60
              return `${hours}小时${minutes}分${secs}秒`
            }

            const usedTimes = finishedData.map(item => item.usedTime)
            stats.maxTime = formatTime(Math.max(...usedTimes))
            stats.minTime = formatTime(Math.min(...usedTimes))
            stats.avgTime = formatTime(Math.floor(usedTimes.reduce((sum, time) => sum + time, 0) / usedTimes.length))
            stats.totalTime = formatTime(usedTimes.reduce((sum, time) => sum + time, 0))
          }

          // 3. 图表数据准备
          const dateMap = new Map()
          data.forEach(item => {
            const date = date_format(item.createTime, "yyyyMMdd")
            if (!dateMap.has(date)) {
              dateMap.set(date, {total: 0, finished: 0})
            }
            dateMap.get(date).total++
            if (item.scoreTime) {
              dateMap.get(date).finished++
            }
          })

          const dates = Array.from(dateMap.keys()).sort()
          const chartData = {
            dates,
            total: dates.map(date => dateMap.get(date).total),
            finished: dates.map(date => dateMap.get(date).finished)
          }

          // 4. 先显示弹窗和更新统计数据
          $this.analysisDialog.stats = stats
          $this.analysisDialog.visible = true

          // 5. 使用 nextTick 等待 DOM 更新后再初始化图表
          $this.$nextTick(() => {
            if ($this.analysisDialog.chart) {
              $this.analysisDialog.chart.dispose()
            }
            $this.analysisDialog.chart = echarts.init($this.$refs.learningChart)

            const option = {
              title: {
                text: '每日学习情况统计',
                left: 'center'
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#999'
                  }
                }
              },
              legend: {
                data: ['学习人数', '完成人数'],
                bottom: 10
              },
              xAxis: {
                type: 'category',
                data: chartData.dates.map(date => {
                  return date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
                }),
                axisPointer: {
                  type: 'shadow'
                }
              },
              yAxis: {
                type: 'value',
                name: '人数',
                minInterval: 1
              },
              series: [
                {
                  name: '学习人数',
                  type: 'bar',
                  data: chartData.total,
                  barMaxWidth: 50
                },
                {
                  name: '完成人数',
                  type: 'line',
                  data: chartData.finished
                }
              ]
            }

            $this.analysisDialog.chart.setOption(option)
          })
        },
        // 获取行政班列表
        async getAdministrationClazzList(name = '') {
          // 处理首次获取焦点的情况
          if (!name && $this.exportLearnLogDialog.isFirstFocus) {
            $this.exportLearnLogDialog.isFirstFocus = false
            // 如果已经有缓存的所有行政班数据，直接使用
            if ($this.exportLearnLogDialog.adminClazzAllOptions.length > 0) {
              $this.$set($this.exportLearnLogDialog, 'adminClazzOptions',
                  $this.exportLearnLogDialog.adminClazzAllOptions)
              // 更新行政班加载状态
              $this.exportLearnLogDialog.listLoadingStatus.adminClazz = true
              return
            }
          }
          // 如果是搜索请求且有缓存，使用缓存的搜索结果
          else if (name && $this.exportLearnLogDialog.adminClazzOptionsCache[name]) {
            $this.$set($this.exportLearnLogDialog, 'adminClazzOptions',
                $this.exportLearnLogDialog.adminClazzOptionsCache[name])
            // 更新行政班加载状态
            $this.exportLearnLogDialog.listLoadingStatus.adminClazz = true
            return
          }
          // 如果不是首次获取焦点且没有搜索内容，保持当前列表不变
          else if (!name && !$this.exportLearnLogDialog.isFirstFocus) {
            return
          }

          // 重置行政班加载状态
          $this.exportLearnLogDialog.listLoadingStatus.adminClazz = false
          $this.exportLearnLogDialog.adminClazzLoading = true
          try {
            let query = {
              schoolId: $this.userInfo.schoolId,
              name: name
            }
            let [list] = await AdministrationClazzModel.getList(1, -1, query)
            let options = list.map(li => ({
              label: li['gradeName'] + ' - ' + li['name'],
              value: li['id']
            }))

            if (!name) {
              $this.$set($this.exportLearnLogDialog, 'adminClazzAllOptions', options)
            } else {
              $this.exportLearnLogDialog.adminClazzOptionsCache[name] = options
            }

            $this.$set($this.exportLearnLogDialog, 'adminClazzOptions', options)
            // 更新行政班加载状态
            $this.exportLearnLogDialog.listLoadingStatus.adminClazz = true
          } catch (error) {
            console.error('获取行政班列表失败:', error)
            msg_err('获取行政班列表失败')
          } finally {
            $this.exportLearnLogDialog.adminClazzLoading = false
          }
        }
      }
    }
  },
  watch: {
    // 打开学习记录弹窗
    'exportLearnLogDialog.dialog': {
      async handler(newVal) {
        if (newVal) {  // 弹窗打开时
          // 重置所有加载状态
          this.exportLearnLogDialog.listLoadingStatus = {
            teacher: false,
            clazz: false,
            adminClazz: false
          }

          if (!this.userInfo.asSecretary) {
            // 非教秘用户，固定teacherId
            this.$set(this.exportLearnLogDialog.query, 'teacherId', [this.userInfo.userid])
            this.$set(this.exportLearnLogDialog, 'teacherOptions', [
              {label: this.userInfo.username, value: this.userInfo.userid}
            ])
            // 更新教师加载状态
            this.exportLearnLogDialog.listLoadingStatus.teacher = true
          } else if (this.exportLearnLogDialog.teacherOptions.length === 0) {
            try {
              const teacherList = await TeacherModel.getOneSchoolAllTeacherList(this.userInfo.schoolid)
              const teacherOptions = teacherList.map(teacher => ({
                value: teacher.id,
                label: teacher.name
              }))
              this.$set(this.exportLearnLogDialog, 'teacherOptions', teacherOptions)
              // 更新教师加载状态
              this.exportLearnLogDialog.listLoadingStatus.teacher = true
            } catch (error) {
              console.error('获取教师列表失败:', error)
              msg_err('获取教师列表失败')
            }
          } else {
            // 如果已有教师列表，直接设置状态为true
            this.exportLearnLogDialog.listLoadingStatus.teacher = true
          }

          // 初始化教学班列表
          try {
            let initTeacherList = []
            if (!this.userInfo.asSecretary) {
              initTeacherList = [
                {id: this.userInfo.userid, name: this.userInfo.username}
              ]
            }
            const clazzList = await LoginLogModel.getAllClazzListByTeachers(
                this.userInfo.schoolid,
                initTeacherList
            )
            this.$set(this.exportLearnLogDialog, 'clazzOptions', clazzList)
            // 更新教学班加载状态
            this.exportLearnLogDialog.listLoadingStatus.clazz = true
          } catch (error) {
            console.error('获取教学班列表失败:', error)
            msg_err('获取教学班列表失败')
          }

          // 初始化行政班列表
          await this.LearnLogMethods().getAdministrationClazzList()
          this.exportLearnLogDialog.isFirstFocus = true
        }
      }
    },
    'exportLearnLogDialog.selectMode': {
      handler(newVal) {
        // 当切换到"全部"时,清空对应的选择
        if (newVal.experiment === 'all') {
          this.exportLearnLogDialog.query.experimentId = []
        }
        if (newVal.teacher === 'all' && this.userInfo.asSecretary) {  // 增限判断
          this.exportLearnLogDialog.query.teacherId = []
        }
        if (newVal.clazz === 'all') {
          this.exportLearnLogDialog.query.clazzId = []
        }
        if (newVal.adminClazz === 'all') {
          this.exportLearnLogDialog.query.administrationClazzId = []
        }
      },
      deep: true
    },
    // 监听教师选择模式变化
    'exportLearnLogDialog.selectMode.teacher': {
      async handler(newVal) {
        if (this.exportLearnLogDialog.dialog) {
          try {
            // 重置教学班加载状态
            this.exportLearnLogDialog.listLoadingStatus.clazz = false
            this.exportLearnLogDialog.query.clazzId = []

            let teacherList = [];
            if (newVal === 'custom' && this.exportLearnLogDialog.query.teacherId.length > 0) {
              teacherList = this.exportLearnLogDialog.query.teacherId.map(id => {
                const teacher = this.exportLearnLogDialog.teacherOptions.find(t => t.value === id);
                return {
                  id: id,
                  name: teacher ? teacher.label : ''
                };
              });
            }
            const clazzList = await LoginLogModel.getAllClazzListByTeachers(
                this.userInfo.schoolid,
                teacherList
            );
            this.$set(this.exportLearnLogDialog, 'clazzOptions', clazzList);
            // 更新教学班加载状态
            this.exportLearnLogDialog.listLoadingStatus.clazz = true
          } catch (error) {
            console.error('获取教学班列表失败:', error);
            msg_err('获取教学班列表失败');
          }
        }
      }
    },
    // 监听选择的教师变化
    'exportLearnLogDialog.query.teacherId': {
      async handler(newVal) {
        if (this.exportLearnLogDialog.dialog &&
            this.exportLearnLogDialog.selectMode.teacher === 'custom') {
          try {
            // 重置教学班加载状态
            this.exportLearnLogDialog.listLoadingStatus.clazz = false
            this.exportLearnLogDialog.query.clazzId = []

            const teacherList = newVal.map(id => {
              const teacher = this.exportLearnLogDialog.teacherOptions.find(t => t.value === id);
              return {
                id: id,
                name: teacher ? teacher.label : ''
              };
            });
            const clazzList = await LoginLogModel.getAllClazzListByTeachers(
                this.userInfo.schoolid,
                teacherList
            );
            this.$set(this.exportLearnLogDialog, 'clazzOptions', clazzList);
            // 更新教学班加载状态
            this.exportLearnLogDialog.listLoadingStatus.clazz = true
          } catch (error) {
            console.error('获取教学班列表失败:', error);
            msg_err('获取教学班列表失败');
          }
        }
      }
    },
    'analysisDialog.visible'(val) {
      if (!val && this.analysisDialog.chart) {
        this.analysisDialog.chart.dispose()
        this.analysisDialog.chart = null
      }
    }
  }
}
</script>

<style scoped lang="less">
// 实验状态相关
.statusDot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  margin-right: 5px;
  vertical-align: middle;
}

.status0 .statusDot {
  background-color: #bfbfbf;
}

.status1 .statusDot {
  background-color: #0e77d1;
}

.status2 .statusDot {
  background-color: #00a854;
}

.dialog-filter .li {
  width: 300px;
  margin-bottom: 30px;

  span {
    display: inline-block;
    width: 70px;
  }
}

.dialog-container {
  padding: 0 20px;

  .dialog-filter {
    .filter-item {
      margin-bottom: 20px;

      .label {
        display: inline-block;
        width: 80px;
        text-align: right;
        margin-right: 10px;
      }

      .separator {
        margin: 0 10px;
      }
    }
  }

  .search-btn-container {
    text-align: center;
    margin: 30px 0;
  }

  .statistics-container {
    background-color: #f5f7fa;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;

    .statistics-info {
      text-align: center;

      .highlight {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
      }
    }
  }

  .dialog-footer {
    text-align: center;
    margin-top: 30px;

    .el-button {
      margin: 0 20px;
    }
  }
}

.analysis-container {
  padding: 20px;

  .filter-summary {
    background: #f5f7fa;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;

    .summary-title {
      font-weight: bold;
      color: #303133;
      margin-bottom: 10px;
    }

    .summary-content {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .summary-item {
        min-width: 45%;

        .label {
          color: #909399;
          margin-right: 8px;
        }

        .value {
          color: #303133;
        }
      }
    }
  }

  .statistics-cards {
    .stat-item {
      text-align: center;

      .stat-title {
        color: #909399;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .stat-value {
        color: #303133;
        font-size: 24px;
        font-weight: bold;
      }

      .stat-sub {
        color: #606266;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
</style>
